<template>
  <v-card width="500">
    <v-system-bar>Kursdokumente</v-system-bar>
    <v-list>
      <ReportItem
        v-for="item in items"
        :key="item.id"
        :value="item"
        :id="course.id"
      />
    </v-list>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import ReportItem from "common/components/ReportItem.vue";

export default defineComponent({
  name: "CourseDocuments",
  props: ["course"],
  components: { ReportItem },
  data() {
    return {
      id: Number,
      items: [],
      loading: false,
    };
  },
  watch: {
    value() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.course) {
        return;
      }
      this.loading = true;
      this.items = await this.apiList({
        resource: "app/report",
        query: "type=course",
      });
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
